var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                {
                  staticClass: "elementPanel__header",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "header__title", attrs: { sm11: "" } },
                    [_c("h2", [_vm._v("QR Code")])]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "header__close", attrs: { sm1: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-space-between": "",
                    "align-baseline": ""
                  }
                },
                [
                  _c("v-flex", { attrs: { sm12: "" } }, [
                    _c("img", { attrs: { src: _vm.qrImage } })
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { sm12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "token1",
                          name: "token1",
                          required: "",
                          label: "Token 1"
                        },
                        model: {
                          value: _vm.token1,
                          callback: function($$v) {
                            _vm.token1 = $$v
                          },
                          expression: "token1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { sm12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "token2",
                          name: "token2",
                          required: "",
                          label: "Token 2"
                        },
                        model: {
                          value: _vm.token2,
                          callback: function($$v) {
                            _vm.token2 = $$v
                          },
                          expression: "token2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "MiButton",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.create($event)
                    }
                  }
                },
                [_vm._v("Token opslaan")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }