var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h2", { staticClass: "header__title ml-0" }, [
                    _vm._v("Uitgesloten postcodes")
                  ]),
                  !_vm.excludedPostalCodes || !_vm.excludedPostalCodes.length
                    ? _c("p", [_vm._v("Er zijn geen uitgesloten postcodes")])
                    : _vm._e(),
                  _vm._l(_vm.excludedPostalCodes, function(excludedPostalCode) {
                    return _c(
                      "v-chip",
                      {
                        key: excludedPostalCode.id,
                        attrs: { close: _vm.canExclude },
                        on: {
                          input: function($event) {
                            return _vm.removePostalCodes(excludedPostalCode)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(excludedPostalCode.code) + " ")]
                    )
                  })
                ],
                2
              ),
              _vm.canExclude
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "with-border",
                        attrs: {
                          items: _vm.postalCodes,
                          "item-text": "code",
                          "item-value": "id",
                          "hide-details": "",
                          placeholder: "Uitsluitbare postcodes",
                          "search-input": _vm.postalCodeSearch,
                          disabled: !_vm.canEdit,
                          "browser-autocomplete": "new-password"
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.postalCodeSearch = $event
                          },
                          "update:search-input": function($event) {
                            _vm.postalCodeSearch = $event
                          },
                          keyup: _vm.debouncedSearch,
                          change: _vm.addPostalCodes
                        },
                        model: {
                          value: _vm.selectedExcludedPostalCode,
                          callback: function($$v) {
                            _vm.selectedExcludedPostalCode = $$v
                          },
                          expression: "selectedExcludedPostalCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }