import { Organization } from '@/models/Organization';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Schedule } from '@/models/Schedule';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DayPart, EventType } from '@/models/Event';
import { Options } from '@/components/mi-dialog/MiDialog';
import { cloneDeep } from 'lodash';
import { User } from '@/models/User';
import { Setting, SettingKey } from '@/models/Setting';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import CreateHolidayDialog from '@/components/dialog/CreateHolidayDialog/CreateHolidayDialog.vue';
import { Holiday } from '@/models/Holiday';
import { formatDate } from '@/support/String';
import AppointmentTypeDialog from '@/components/dialog/appointment-type-dialog/AppointmentTypeDialog.vue';
import { ModifiedAppointmentType } from '@/components/dialog/appointment-type-dialog/AppointmentTypeDialog';

@Component<WorkHours>({
  components: {
    CreateHolidayDialog,
    AppointmentTypeDialog,
  },
})
export default class WorkHours extends Vue {
  public $pageTitle = 'Tijden';

  @Prop({ default: true })
  protected canEdit!: boolean;

  @Prop()
  protected user!: User;

  @Prop()
  protected userId!: string;

  @Prop()
  protected userNote!: string;

  protected maxLimit: null | number = null;

  protected settings: Setting[] = [];

  protected isCreatingHolidayDialog = false;

  protected isDisplayingAppointmentTypeDialog = false;

  protected appointmentType: AppointmentTypeSettings = {};

  protected selectedHoliday: Holiday | null = null;

  protected visibility: TableVisibility = {
    checkboxes: false,
    search: false,
    total: true,
    title: false,
  };

  protected limitRule(value: string) {
    if (this.maxLimit === null) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return Number(value) > this.maxLimit ? 'Gekozen aantal is hoger dan het limiet' : true;
  }

  // user note
  protected note = '';

  protected isLoading = false;

  protected hasBeenChanged = false;

  protected hasBeenParsed = false;

  protected selectedToggle: string | null = null;

  protected availabilityIcon: { [key: string]: string } = {
    free: 'check_circle',
    busy: 'remove_circle',
    internal: 'apartment',
    invalid: 'help',
  };

  protected uneven: WorkDay[] = [];

  protected even: WorkDay[] = [];

  protected days: WorkDay[] = [
    {
      day: 'monday',
      label: 'maandag',
      short_label: 'ma',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'tuesday',
      label: 'dinsdag',
      short_label: 'di',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'wednesday',
      label: 'woensdag',
      short_label: 'wo',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'thursday',
      label: 'donderdag',
      short_label: 'do',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'friday',
      label: 'vrijdag',
      short_label: 'vr',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'saturday',
      label: 'zaterdag',
      short_label: 'za',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
    {
      day: 'sunday',
      label: 'zondag',
      short_label: 'zo',
      starts_at: '09:00',
      ends_at: '17:00',
      daypart_morning: null,
      daypart_afternoon: null,
      max_appointment_count: null,
      max_appointment_type_count: null,
    },
  ];

  protected showAppointmentTypeDialog(day: WorkDay) {
    this.isDisplayingAppointmentTypeDialog = true;
    this.appointmentType.day = day;
  }

  protected updateHours() {
    let allDays: WorkDay[] = [];

    this.even.forEach((evenDay: WorkDay) => {
      const foundDay = this.days.find((day: WorkDay) => day.day === evenDay.day);

      if (foundDay) {
        evenDay.starts_at = foundDay.starts_at;
        evenDay.ends_at = foundDay.ends_at;
        if (! foundDay.week_type) {
          evenDay.week_type = 'even';
        }
      }
    });

    this.uneven.forEach((unevenDay: WorkDay) => {
      const foundDay = this.days.find((day: WorkDay) => day.day === unevenDay.day);

      if (foundDay) {
        unevenDay.starts_at = foundDay.starts_at;
        unevenDay.ends_at = foundDay.ends_at;
        if (! foundDay.week_type) {
          unevenDay.week_type = 'uneven';
        }
      }
    });

    allDays = [...this.even, ...allDays];
    allDays = [...this.uneven, ...allDays];

    // For IMG users exclude the max_appointment_count property for now, since it sends a max_appointment_type_count and otherwise confuses the API
    if (this.hasAppointmentSubdivision) {
      allDays = allDays.map((day) => ({
        day: day.day,
        label: day.label,
        short_label: day.short_label,
        starts_at: day.starts_at,
        ends_at: day.ends_at,
        week_type: day.week_type,
        daypart_morning: day.daypart_morning,
        daypart_afternoon: day.daypart_afternoon,
        max_appointment_type_count: day.max_appointment_type_count,
      }));
    }

    new Schedule()
      .user(this.userId ? this.userId : this.$route.params.id)
      .put({ days: allDays })
      .then(() => {
        this.hasBeenChanged = false;
        this.$store.dispatch('openDialog', this.successDialogOptions);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected isDisabledDay(day: WorkDay) {
    if (! day.starts_at || ! day.ends_at) {
      return true;
    }

    return false;
  }

  public mounted() {
    this.initialize();
    this.note = cloneDeep(this.userNote);
  }

  protected initialize() {
    this.getSchedule();
    this.getSettings();
  }

  protected onClickCreateHoliday() {
    this.selectedHoliday = null;
    this.isCreatingHolidayDialog = true;
  }

  protected updateMaxAppointmentTypeCount(appointmentTypes: ModifiedAppointmentType[], currentDay: WorkDay) {
    if (! currentDay.week_type) {
      return;
    }

    this[currentDay.week_type].forEach((day) => {
      if (day.label === currentDay.label) {
        const filteredAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType.value !== undefined);
        const value = Object.fromEntries(filteredAppointmentTypes.map((filteredAppointmentType) => [filteredAppointmentType.key, filteredAppointmentType.value || '']));
        this.$set(day, 'max_appointment_type_count', value);

        const total = filteredAppointmentTypes.map((filteredAppointmentType) => Number(filteredAppointmentType.value)).reduce((a, b) => a + b, 0);
        this.$set(day, 'max_appointment_count', total);
      }
    });

    this.$set(this, currentDay.week_type, this[currentDay.week_type]);

    this.hasBeenChanged = true;
  }

  protected async getSettings() {
    this.isLoading = true;

    const organizations = await new Organization().all();

    const organization = organizations.find((organization: Organization) => organization.id === this.user.organization?.id);

    if (! organization) {
      this.isLoading = false;
      return;
    }

    const settings = await new Setting()
      .dmz(organization.id)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.settings = settings;
    const setting = settings.find((currentSetting: Setting) => (currentSetting.key ? currentSetting.key.includes(this.user.is_opnemer ? SettingKey.MAX_VISIT_OPNEMER : SettingKey.MAX_VISIT_DESKUNDIGE) : false));

    this.maxLimit = setting?.value && setting.value > 0 ? Number(setting.value) : 0;
    this.isLoading = false;
  }

  protected get canSubmitHours() {
    let hoursValid = true;
    // eslint-disable-next-line consistent-return
    this.days.forEach((day: WorkDay) => {
      if (! this.isValidDay(day)) {
        return false;
      }
    });

    this.uneven.forEach((day: WorkDay) => {
      if (this.maxLimit !== null && Number(day.max_appointment_count) > this.maxLimit) {
        hoursValid = false;
      }
    });

    this.even.forEach((day: WorkDay) => {
      if (this.maxLimit !== null && Number(day.max_appointment_count) > this.maxLimit) {
        hoursValid = false;
      }
    });

    return hoursValid;
  }

  protected isValidDay(day: WorkDay) {
    if (! day.starts_at && ! day.ends_at) {
      return true;
    }

    if (! day.starts_at || ! day.ends_at) {
      return false;
    }

    const startHour = day.starts_at.split(':')[0];
    const endHour = day.ends_at.split(':')[0];

    if (endHour <= startHour) {
      return false;
    }

    return true;
  }

  protected getSchedule() {
    this.uneven = this.$set(this, 'uneven', cloneDeep(this.days));
    this.even = this.$set(this, 'even', cloneDeep(this.days));

    this.even = this.even.map((day) => {
      day.week_type = 'even';
      return day;
    });

    this.uneven = this.uneven.map((day) => {
      day.week_type = 'uneven';
      return day;
    });

    new Schedule()
      .user(this.userId ? this.userId : this.$route.params.id)
      .all()
      .then((schedules: Schedule[]) => {
        schedules.forEach((schedule: Schedule) => {
          if (schedule.week_type === 'even') {
            const day = this.even.find((currentDay: WorkDay) => currentDay.day === schedule.day);

            if (day) {
              day.starts_at = (schedule.starts_at as string).substr(0, 5);
              day.ends_at = (schedule.ends_at as string).substr(0, 5);
              day.daypart_morning = schedule.daypart_morning;
              day.daypart_afternoon = schedule.daypart_afternoon;
              day.week_type = schedule.week_type;
              day.max_appointment_count = schedule.max_appointment_count;
              day.max_appointment_type_count = schedule.max_appointment_type_count;
            }
          } else {
            const day = this.uneven.find((currentDay: WorkDay) => currentDay.day === schedule.day);

            if (day) {
              day.starts_at = (schedule.starts_at as string).substr(0, 5);
              day.ends_at = (schedule.ends_at as string).substr(0, 5);
              day.daypart_morning = schedule.daypart_morning;
              day.daypart_afternoon = schedule.daypart_afternoon;
              day.week_type = schedule.week_type;
              day.max_appointment_count = schedule.max_appointment_count;
              day.max_appointment_type_count = schedule.max_appointment_type_count;
            }
          }
        });

        this.days.forEach((evenDay: WorkDay) => {
          const foundDay = this.even.find((day: WorkDay) => day.day === evenDay.day);

          if (foundDay) {
            evenDay.starts_at = foundDay.starts_at;
            evenDay.ends_at = foundDay.ends_at;
          }
        });

        this.$nextTick(() => {
          this.hasBeenParsed = true;
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getEventType(day: WorkDay, daypart: DayPart) {
    const daypartType = daypart === 'morning' ? day.daypart_morning : day.daypart_afternoon;
    if (daypartType === null) {
      return '';
    }

    return daypartType ? 'free' : 'busy';
  }

  protected getEventIcon(day: WorkDay, daypart: DayPart) {
    const daypartStatus = daypart === 'morning' ? day.daypart_morning : day.daypart_afternoon;

    if (! daypartStatus) {
      return this.availabilityIcon.invalid;
    }

    return this.availabilityIcon[daypartStatus];
  }

  protected selectToggle(type: string) {
    if (this.selectedToggle === type) {
      this.selectedToggle = null;
      return;
    }

    this.selectedToggle = type;
  }

  protected setAvailability(day: WorkDay, daypart: DayPart, type: EventType) {
    daypart === 'morning' ? (day.daypart_morning = type) : (day.daypart_afternoon = type);
  }

  protected handleCellClick(day: WorkDay, daypart: DayPart) {
    if (! this.selectedToggle) {
      this.$store.dispatch('openDialog', this.editModeDialogOptions);
      return;
    }

    if (! this.$store.state.Auth.uuid || ! day || ! daypart) {
      return;
    }

    this.setAvailability(day, daypart, this.selectedToggle as EventType);
  }

  protected updateUserNote() {
    new User({ id: this.userId })
      .update({
        schedule_comment: this.note,
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected refreshTable(): void {
    if (! this.$refs.holidayDatatable) {
      return;
    }
    (this.$refs.holidayDatatable as DataTable).refresh();
  }

  protected get hasAppointmentSubdivision(): boolean {
    return this.$store.state.isServiceOrganization;
  }

  protected get editModeDialogOptions(): Options {
    return {
      title: 'U heeft geen status geselecteerd',
      text: 'Om de beschikbaarheid aan te passen selecteer dan eerst een status in de werkbalk.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get successDialogOptions(): Options {
    return {
      title: 'Werktijden en beschikbaarheid succesvol opgeslagen',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get tableOptions(): Object {
    return {
      model: new Holiday().include(['user', 'comment']).filter({ user: this.userId ? this.userId : this.$route.params.id }),
      headers: [
        {
          text: 'Van',
          value: 'starts_at',
          transform: (starts_at: string) => (starts_at && starts_at !== '0000-00-00 00:00:00' ? formatDate(starts_at) : '-'),
          width: '20%',
          sortable: {
            key: 'starts_at',
            order: 'DESC',
          },
        },
        {
          text: 'Tot',
          value: 'ends_at',
          transform: (ends_at: string) => (ends_at && ends_at !== '0000-00-00 00:00:00' ? formatDate(ends_at) : '-'),
          width: '20%',
          sortable: {
            key: 'ends_at',
            order: 'DESC',
          },
        },
        {
          text: 'Opmerking',
          value: 'comment',
        },
        // {
        //   text: 'Status',
        //   value: 'status',
        //   transform: (status: string) => {
        //     return status ? `<span class="statusPill statusBackgroundColor--${holidayStatusColors[status]}">${holidayStatusLabels[status]}</span>` : '-';
        //   },
        // },
      ],
      actions: [
        {
          type: 'edit',
          label: 'edit',
          icon: 'edit',
          action: (_: DataTable, selectedHoliday: Holiday) => {
            this.selectedHoliday = selectedHoliday;
            this.isCreatingHolidayDialog = true;
          },
          tooltip: 'Verander vakantie',
        },
        {
          type: 'delete',
          label: 'delete',
          icon: 'delete',
          action: async (_: DataTable, selectedHoliday: Holiday) => {
            this.selectedHoliday = selectedHoliday;
            this.$store.dispatch('openDialog', this.dialogOptionsDelete);
          },
          tooltip: 'Verwijder vakantie',
        },
      ],
    };
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet u zeker dat u de vakantie van <br> ${this.selectedHoliday?.starts_at ? formatDate(this.selectedHoliday.starts_at) : ''} tot ${this.selectedHoliday?.ends_at ? formatDate(this.selectedHoliday.ends_at) : ''} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: async () => {
            if (! this.selectedHoliday) return;

            await new Holiday(this.selectedHoliday).delete().catch((error: AxiosError) => {
              ErrorHandler.network(error);
            });

            this.refreshTable();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
        },
      },
    };
  }

  @Watch('days', { deep: true })
  protected dayChanged() {
    if (this.hasBeenParsed) {
      this.hasBeenChanged = true;
    }
  }

  @Watch('$route')
  public routeChanged() {
    this.initialize();
  }
}

export interface WorkDay {
  day: string;
  label: string;
  short_label: string;
  starts_at: string | null;
  ends_at: string | null;
  week_type?: WeekType | null;
  daypart_morning?: EventType | null;
  daypart_afternoon?: EventType | null;
  max_appointment_count?: string | null;
  max_appointment_type_count?: {
    [key: string]: string;
  } | null;
}

interface AppointmentTypeSettings {
  day?: WorkDay;
}

export type WeekType = 'uneven' | 'even';
