import { cloneDeep } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, userRoles } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Skill } from '@/models/Skill';
import { Area } from '@/models/Area';

@Component<UserAreas>({})
export default class UserAreas extends Vue {
  @Prop()
  protected user!: User | null;

  @Prop()
  protected organization!: string;

  @Prop()
  protected userId!: string;

  protected skills: Skill[] = [];

  protected userSkills: Skill[] = [];

  protected userAreas: Area[] = [];

  protected areas: Area[] = [];

  protected selectedAreas: string[] = [];

  protected skillList: { [key: string]: boolean } = {};

  protected userClone: User | null = null;

  protected userRoles = userRoles;

  protected mounted() {
    this.initialize();
  }

  protected initialize() {
    if (! this.user) {
      return;
    }

    this.userClone = cloneDeep(this.user);
    if (this.organization) {
      this.userClone = this.userClone.dmz(this.organization);
    }

    this.handleAreas();
  }

  private createPayloadFromIdList(idList: string[]) {
    return idList.map((areaId: string) => ({
      id: areaId,
    }));
  }

  protected sendSaveAreasRequestToAPI() {
    if (! this.userClone) {
      return;
    }

    const payload = this.createPayloadFromIdList(this.selectedAreas);
    this.userClone
      .dmz(this.organization)
      .AddAreas(this.userClone.uuid, payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected removeArea(region: Area) {
    if (region && region.id) {
      const index = this.selectedAreas.indexOf(region.id);
      if (index >= 0) {
        this.selectedAreas.splice(index, 1);
        this.sendSaveAreasRequestToAPI();
      }
    }
  }

  protected handleAreas() {
    this.resetAreas();

    Promise.all([this.getAreas(), this.getUserAreas()])
      .then((results: [Area[] | AxiosError, Area[] | AxiosError]) => {
        this.areas = results[0] as Area[];
        this.userAreas = results[1] as Area[];

        this.selectedAreas = this.userAreas.map((userArea: Area) => (userArea.id ? userArea.id : ''));
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected resetAreas() {
    this.userAreas = [];
    this.areas = [];
    this.$set(this, 'selectedAreas', []);
  }

  protected getAreas(): Promise<Area[] | AxiosError> {
    return new Area()
      .all()
      .then((areas: Area[]) => Promise.resolve(areas))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected getUserAreas(): Promise<Area[] | AxiosError> {
    const user = this.organization ? new User().dmz(this.organization) : new User();

    return user
      .getAreas(this.userId ? this.userId : this.$route.params.id)
      .then((areas: Area[]) => Promise.resolve(areas))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected get isServiceOrganization() {
    return this.$store.state.isServiceOrganization;
  }

  protected get isPlanner() {
    return this.$store.state.Auth.is_planner;
  }

  protected get isManager() {
    return this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles);
  }
}
