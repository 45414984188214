import { Experience, ExperienceType } from '@/models/Experience';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Level, User, userLevelLabels } from '@/models/User';
import { DateFormatter } from '@/support/DateFormatter';
import { Skill } from '@/models/Skill';
import EnrollmentList from '@/views/Users/ExpertRegister/EnrollmentList/EnrollmentList.vue';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<ExpertRegister>({
  components: {
    EnrollmentList,
  },
})

export default class ExpertRegister extends Vue {
  @Prop()
  protected user!: User | null;

  @Prop()
  protected organization!: string;

  @Prop({ default: true })
  protected readOnly!: boolean;

  protected experiences: {[key: string]: Experience[]} = {}

  protected isLoading = true;

  protected isCreating = false;

  protected skills: Skill[] = [];

  protected startedWorkingAtMenu = false;

  protected expertRegister = {
    startedWorkingAt: new DateFormatter(),
    postcode: '',
  };

  protected async mounted() {
    this.parseUserData();
    this.isLoading = false;
  }

  protected async handleUpdate(value: string, key: string) {
    if (! this.user) {
      return;
    }

    const model = this.organization ? new User(this.user).dmz(this.organization) : new User(this.user);

    await model
      .update({
        [key]: `${value}`,
      });

    this.reload();
  }

  protected async createDeclarationOfIndependence() {
    if (! this.user) { return; }

    this.isCreating = true;
    const response = await new User(this.user)
      .dmz(this.organization || this.user.organization?.id || '')
      .exportDeclarationOfIndependence()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response) {
      this.isCreating = false;
      this.openFileUrl(response.file_url);
    }
  }

  protected reload() {
    this.$emit('reload');
  }

  protected openFileUrl(url: string) {
    if (! url) { return; }
    window.open(url, '_blank');
  }

  protected fetchExperiences() {
    this.$emit('fetchExperiences');
  }

  protected getLevelLabel(level: string) {
    return userLevelLabels[level];
  }

  protected get isJunior() {
    return this.user?.level === Level.JUNIOR_DESKUNDIGE;
  }

  protected get isProcessexpert() {
    return this.user?.level === Level.PROCESEXPERT;
  }

  protected get isOpnemer() {
    return !! this.user?.is_opnemer;
  }

  @Watch('user')
  protected parseUserData() {
    if (this.user) {
      this.expertRegister.startedWorkingAt.selectedDate = this.user.started_working_at && this.user.started_working_at === '0000-00-00 00:00:00' ? '' : this.user.started_working_at || '';
      this.expertRegister.postcode = this.user?.postcode || '';
    }
  }

  protected get ExperienceType() {
    return ExperienceType;
  }
}
