var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { "sm-12": "" } }, [
            _c("h2", { staticClass: "header__title ml-0" }, [
              _vm._v("Bewerk toegewezen gebieden")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-autocomplete", {
                staticClass: "my-3 with-border areasAutocomplete",
                attrs: {
                  "prepend-icon": "map",
                  label: "Gebieden",
                  items: _vm.areas,
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "",
                  chips: "",
                  multiple: "",
                  "persistent-hint": "",
                  disabled:
                    !_vm.isServiceOrganization ||
                    !_vm.isPlanner ||
                    !_vm.isManager
                },
                on: {
                  change: function($event) {
                    return _vm.sendSaveAreasRequestToAPI()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(data) {
                      return [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              selected: data.selected,
                              close:
                                _vm.isServiceOrganization &&
                                _vm.isPlanner &&
                                _vm.isManager
                            },
                            on: {
                              input: function($event) {
                                return _vm.removeArea(data.item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(data.item.name))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedAreas,
                  callback: function($$v) {
                    _vm.selectedAreas = $$v
                  },
                  expression: "selectedAreas"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }