var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: { label: "Voeg hier uw opmerking toe..." },
                model: {
                  value: _vm.payload.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.payload, "comment", $$v)
                  },
                  expression: "payload.comment"
                }
              }),
              !_vm.isSelf
                ? _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      label: "Zichtbaar voor de gebruiker"
                    },
                    model: {
                      value: _vm.payload.is_self_readable,
                      callback: function($$v) {
                        _vm.$set(_vm.payload, "is_self_readable", $$v)
                      },
                      expression: "payload.is_self_readable"
                    }
                  })
                : _vm._e(),
              _c(
                "MiButton",
                {
                  staticClass: "mt-2",
                  attrs: { color: "primary", icon: "send", compact: true },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onClickCreateLog()
                    }
                  }
                },
                [_vm._v(" Bericht versturen ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-flex",
            {
              ref: "logContainer",
              staticClass: "userlogContainer report-messages",
              attrs: { sm12: "" }
            },
            [
              !_vm.logs || !_vm.logs.length
                ? _c("span", { staticClass: "fadedText" }, [
                    _vm._v("Geen logs van " + _vm._s(_vm.user.name || ""))
                  ])
                : _vm._e(),
              _vm._l(_vm.logs, function(log, index) {
                return _c(
                  "div",
                  {
                    key: "default-" + log.id + index,
                    staticClass: "report-message"
                  },
                  [
                    _c("div", { staticClass: "mt-3" }, [
                      _c(
                        "div",
                        { staticClass: "report-message__block comment" },
                        [
                          _c("div", { staticClass: "message__meta" }, [
                            _c("span", { staticClass: "message__created" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormatTime")(log.created_at)
                                  ) +
                                  " "
                              ),
                              !log.is_self_readable
                                ? _c("span", { staticClass: "intern" }, [
                                    _vm._v("Prive")
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _c("pre", {
                            staticClass: "message__text white-space",
                            domProps: {
                              innerHTML: _vm._s(_vm.parseLogText(log))
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }