var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm.canLoginAs
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("p", [
                    _vm._v("Hiermee kun je inloggen als deze gebruiker.")
                  ]),
                  _c(
                    "MiButton",
                    {
                      attrs: {
                        icon: "input",
                        round: "true",
                        outline: "true",
                        background: "true"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.loginAs(_vm.user)
                        }
                      }
                    },
                    [_vm._v(" Inloggen als ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("p", [_vm._v("Hiermee kun je de MFA token instellen.")]),
              _c(
                "MiButton",
                {
                  attrs: {
                    icon: "lock",
                    round: "true",
                    outline: "true",
                    background: "true"
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.isOpeningQRDialog = true
                    }
                  }
                },
                [_vm._v(" MFA token instellen ")]
              )
            ],
            1
          ),
          _vm.user.mfa_token
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("p", [
                    _vm._v(
                      "Hiermee kun je de MFA token van dit account verwijderen."
                    )
                  ]),
                  _c(
                    "MiButton",
                    {
                      attrs: {
                        icon: "lock",
                        round: "true",
                        outline: "true",
                        background: "true"
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteMfaToken()
                        }
                      }
                    },
                    [_vm._v(" MFA token verwijderen ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isOpeningQRDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw", persistent: true },
              model: {
                value: _vm.isOpeningQRDialog,
                callback: function($$v) {
                  _vm.isOpeningQRDialog = $$v
                },
                expression: "isOpeningQRDialog"
              }
            },
            [
              _c("QRDialog", {
                attrs: { user: _vm.user },
                model: {
                  value: _vm.isOpeningQRDialog,
                  callback: function($$v) {
                    _vm.isOpeningQRDialog = $$v
                  },
                  expression: "isOpeningQRDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }