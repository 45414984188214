var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "v-layout",
          { attrs: { xs12: "", wrap: "" } },
          [
            _vm.isLoading
              ? [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            !_vm.isLoading
              ? [
                  _c(
                    "div",
                    { staticClass: "flex formContainer" },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "formPanel", attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "panelHeader",
                              attrs: { "mb-2": "", xs6: "" }
                            },
                            [_vm._v("Disclosure statement")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "panelHeader",
                              attrs: {
                                "mb-2": "",
                                xs6: "",
                                "text-xs-right": ""
                              }
                            },
                            [
                              !_vm.isProcessexpert
                                ? _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        icon: "cloud_download",
                                        round: "true",
                                        outline: "true",
                                        background: "true",
                                        disabled: _vm.isCreating
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.createDeclarationOfIndependence()
                                        }
                                      }
                                    },
                                    [_vm._v(" Exporteren ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false,
                                            "max-width": "290"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "pt-0 input input-date",
                                                          attrs: {
                                                            disabled:
                                                              _vm.readOnly,
                                                            placeholder:
                                                              "Kies een datum",
                                                            label:
                                                              "Werkzaam als schade-expert sinds",
                                                            "error-messages":
                                                              _vm.expertRegister
                                                                .startedWorkingAt
                                                                .errorMessage
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.expertRegister
                                                                .startedWorkingAt
                                                                .formattedDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .expertRegister
                                                                  .startedWorkingAt,
                                                                "formattedDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "expertRegister.startedWorkingAt.formattedDate"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2042391035
                                          ),
                                          model: {
                                            value: _vm.startedWorkingAtMenu,
                                            callback: function($$v) {
                                              _vm.startedWorkingAtMenu = $$v
                                            },
                                            expression: "startedWorkingAtMenu"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            ref: "picker",
                                            attrs: {
                                              locale: "nl-nl",
                                              color: "#837f16",
                                              "first-day-of-week": "1"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleUpdate(
                                                  $event,
                                                  "started_working_at"
                                                )
                                              },
                                              input: function($event) {
                                                _vm.startedWorkingAtMenu = false
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.expertRegister
                                                  .startedWorkingAt
                                                  .selectedDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.expertRegister
                                                    .startedWorkingAt,
                                                  "selectedDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "expertRegister.startedWorkingAt.selectedDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { x12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          disabled: _vm.readOnly,
                                          label: "Beschikbaarheid in de FTE",
                                          placeholder:
                                            "0.0, 0.1, 0.2, 0.3, etc t/m 1.0"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.handleUpdate(
                                              $event,
                                              "available_fte"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.user.available_fte,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "available_fte",
                                              $$v
                                            )
                                          },
                                          expression: "user.available_fte"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "pt-0",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  label: "Postcode",
                                                  placeholder: "vb: 1012CW"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.handleUpdate(
                                                      $event,
                                                      "postcode"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.expertRegister.postcode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.expertRegister,
                                                      "postcode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "expertRegister.postcode"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Niveau")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      [
                                        _c("v-icon", { staticClass: "mr-1" }, [
                                          _vm._v("poll")
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getLevelLabel(_vm.user.level)
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Competenties")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      _vm._l(_vm.user.fixed_skills, function(
                                        skill,
                                        index
                                      ) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: "skill-" + index,
                                            style:
                                              "background-color: " +
                                              skill.color +
                                              " !important"
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(skill.name))
                                            ])
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ]),
                                  _vm.user.verified_assessment
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              " Assessment is gezien en akkoord. "
                                            )
                                          ])
                                        ])
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                [
                                  _vm.isJunior &&
                                  _vm.user.declaration_of_expertise &&
                                  _vm.user.declaration_of_expertise.original
                                    ? _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                icon: "cloud_download",
                                                round: "true",
                                                outline: "true",
                                                background: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.openFileUrl(
                                                    _vm.user
                                                      .declaration_of_expertise
                                                      .original
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Verklaring van deskundigheid "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.user.declaration_of_good_behaviour &&
                                  _vm.user.declaration_of_good_behaviour
                                    .original
                                    ? _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                icon: "cloud_download",
                                                round: "true",
                                                outline: "true",
                                                background: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.openFileUrl(
                                                    _vm.user
                                                      .declaration_of_good_behaviour
                                                      .original
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Verklaring omtrent gedrag "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.isOpnemer
                        ? [
                            _vm.user
                              ? _c("EnrollmentList", {
                                  attrs: {
                                    user: _vm.user,
                                    experiences: _vm.experiences,
                                    organization: _vm.organization,
                                    title: "Opleiding(en)",
                                    type: _vm.ExperienceType.SCHOOL,
                                    singular: "Opleiding",
                                    noResultsText:
                                      "Er zijn nog geen opleiding(en) toegevoegd",
                                    readOnly: _vm.readOnly
                                  },
                                  on: { reload: _vm.fetchExperiences }
                                })
                              : _vm._e(),
                            _vm.user
                              ? _c("EnrollmentList", {
                                  attrs: {
                                    user: _vm.user,
                                    experiences: _vm.experiences,
                                    organization: _vm.organization,
                                    title: "Werkervaring",
                                    type: _vm.ExperienceType.WORK,
                                    singular: "Ervaring",
                                    noResultsText:
                                      "Er is nog geen werkervaring toegevoegd",
                                    readOnly: _vm.readOnly
                                  },
                                  on: { reload: _vm.fetchExperiences }
                                })
                              : _vm._e(),
                            _vm.user
                              ? _c("EnrollmentList", {
                                  attrs: {
                                    user: _vm.user,
                                    experiences: _vm.experiences,
                                    organization: _vm.organization,
                                    title: "Nevenfuncties",
                                    type: _vm.ExperienceType.SIDE_ACTIVITY,
                                    singular: "Nevenfunctie",
                                    noResultsText:
                                      "Er zijn nog geen Nevenfunctie(s) toegevoegd",
                                    readOnly: _vm.readOnly
                                  },
                                  on: { reload: _vm.fetchExperiences }
                                })
                              : _vm._e(),
                            _vm.user
                              ? _c("EnrollmentList", {
                                  attrs: {
                                    user: _vm.user,
                                    experiences: _vm.experiences,
                                    organization: _vm.organization,
                                    title: "Bijzondere expertises",
                                    type: _vm.ExperienceType.SPECIAL_EXPERTISE,
                                    singular: "Bijzondere expertise",
                                    noResultsText:
                                      "Er is nog geen Bijzondere expertises toegevoegd",
                                    readOnly: _vm.readOnly
                                  },
                                  on: { reload: _vm.fetchExperiences }
                                })
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }