import { getStyledStatusLog } from '@/support/ReportStatus';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { User } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { UserLog } from '@/models/UserLog';

@Component<UserCommunication>({})
export default class UserCommunication extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User;
  */
  // #endregion
  @Prop({ default: null })
  protected user!: User;

  @Prop({ default: '' })
  protected organization!: string;

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = true;

  protected logs: UserLog[] = [];

  protected page = 1;

  protected pageSize = 0;

  protected debouncedScroll: Function = debounce(this.scrollLogContainer, 500);

  protected planningChangeRegex = /(.*):\n[-]+[\n]{2}(.|\n)*/g;

  protected payload: LogPayload = {
    comment: '',
    is_self_readable: false,
  };
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected async mounted(): Promise<void> {
    this.page = 1;
    await this.fetchLogs();
    window.addEventListener('scroll', this.scrollEvent);

    this.payload.is_self_readable = this.isSelf;
  }
  // #endregion

  // #region Class methods

  protected scrollLogContainer() {
    if (! this.$refs.logContainer) {
      return;
    }

    const container: any = this.$refs.logContainer;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = container.getBoundingClientRect();
    const offset = elemRect.bottom - bodyRect.bottom;

    if (scrollPosition > Math.floor(offset) - 10) {
      if (this.page === 1 && this.pageSize === 1) return;
      if (this.page >= this.pageSize) return;
      this.page ++;
      this.fetchLogs();
    }
  }

  protected scrollEvent() {
    this.debouncedScroll();
  }

  protected parseLogText(log: UserLog) {
    if (log?.text) {
      return getStyledStatusLog(log.text, log.type);
    }

    return '';
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchLogs(): Promise<void> {
    if (! this.user) {
      return;
    }

    this.isLoading = true;
    const model = this.organization ? new UserLog().dmz(this.organization) : new UserLog();

    const logs: UserLog[] = await model
      .filter({ user: this.user.id })
      .limit(20)
      .page(this.page)
      .sort('created_at', 'DESC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    this.logs = [...logs, ...this.logs];
    if (logs[0]) {
      this.pageSize = (logs[0].meta as any).last_page;
    }

    this.isLoading = false;
  }

  protected async onClickCreateLog() {
    if (! this.user) {
      return;
    }
    const model = this.organization ? new UserLog().dmz(this.organization) : new UserLog();

    this.isLoading = true;
    await model
      .filter({ user: this.user.id })
      .create(this.payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.logs = [];
    this.page = 1;
    this.pageSize = 0;
    this.payload.comment = '';

    this.fetchLogs();
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get isSelf() {
    return this.user.uuid === this.$store.state.Auth.uuid;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  @Watch('company')
  protected userChanged() {
    this.logs = [];
    this.page = 1;
    this.pageSize = 0;
    this.fetchLogs();
  }
  // #endregion
}

interface LogPayload {
  comment: string;
  is_self_readable: boolean;
}
