import { DateFormatter } from '@/support/DateFormatter';
import { AxiosError } from 'axios';
import { User } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CreateHolidayPayload, Holiday } from '@/models/Holiday';
import { cloneDeep } from 'lodash';

@Component<CreateHolidayDialog>({})
export default class CreateHolidayDialog extends Vue {
  @Prop()
  protected user!: User;

  @Prop()
  protected holiday!: Holiday;

  protected internalHoliday: Holiday | null = null;

  protected isLoading = false;

  protected comment = '';

  protected fromDate = new DateFormatter();

  protected toDate = new DateFormatter();

  protected mounted() {
    this.internalHoliday = cloneDeep(this.holiday);

    this.comment = this.internalHoliday.comment || '';
    this.fromDate.selectedDate = this.internalHoliday.starts_at || '';
    this.toDate.selectedDate = this.internalHoliday.ends_at || '';
  }

  protected close() {
    this.$emit('input', false);
  }

  protected async create() {
    this.isLoading = true;

    if (this.holiday) {
      await this.editHoliday();
    } else {
      await this.createHoliday();
    }

    this.isLoading = false;
    this.$emit('holidayCreated');
    this.close();
  }

  protected async createHoliday() {
    if (! this.user?.uuid) {
      return;
    }

    const payload: CreateHolidayPayload = {
      user: this.user.uuid,
      starts_at: this.fromDate && this.fromDate.selectedDate ? this.fromDate.selectedDate : '',
      ends_at: this.toDate && this.toDate.selectedDate ? this.toDate.selectedDate : '',
      comment: this.comment,
    };

    await new Holiday().create(payload).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected async editHoliday() {
    if (! this.holiday?.id) {
      return;
    }

    const payload: EditHolidayPayload = {
      starts_at: this.fromDate && this.fromDate.selectedDate ? this.fromDate.selectedDate : '',
      ends_at: this.toDate && this.toDate.selectedDate ? this.toDate.selectedDate : '',
      comment: this.comment,
    };

    await new Holiday(this.holiday).update(payload).catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
  }

  protected get isDisabled(): boolean {
    if (this.user && this.fromDate.selectedDate && this.toDate.selectedDate) {
      return false;
    }

    return true;
  }
}

interface EditHolidayPayload {
  starts_at: string;
  ends_at: string;
  comment: string;
}
