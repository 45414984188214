import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Token } from '@/support/Token';
import { Options } from '@/components/mi-dialog/MiDialog';
import QRDialog from '@/views/Users/QRDialog/QRDialog.vue';
import { UserFilter } from '@/models/UserFilter';

@Component<UserActions>({
  components: {
    QRDialog,
  },
})

export default class UserActions extends Vue {
  @Prop()
  protected user!: User | null;

  protected isOpeningQRDialog = false;

  protected loginAs(user: User) {
    new User()
      .loginAs(user.uuid as string)
      .then((user: User) => {
        if (! user || ! user.token) {
          return;
        }
        Token.delete(['access', 'refresh']);
        Token.set({ access: (user.token as any).bearer });
        new User(user).current().then((user: User) => {
          this.$store.dispatch('userAuthenticated', user);
          this.getUserFilters();
          this.redirect();
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getUserFilters() {
    new UserFilter()
      .limit(5000)
      .all()
      .then((userFilters: UserFilter[]) => {
        this.$store.dispatch('changeUserFilters', userFilters);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public redirect() {
    if (this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect.toString());
    } else {
      this.$router.push('/reports');
    }
  }

  protected get canLoginAs(): boolean {
    if (! this.user) return false;

    if (this.$store.state.Auth.uuid === this.user.uuid) {
      return false;
    }
    if (this.user.type === 'simple' || this.user.type === 'simple_plus') {
      return false;
    }

    return this.user.hasBearerToken && this.$store.state.Auth.isAtabix;
  }

  protected deleteMfaToken() {
    this.$store.dispatch('openDialog', this.mfaDialogOptions);
  }

  protected deleteMfaRequest() {
    if (! this.user) return;
    new User()
      .deleteMfa(this.user.uuid)
      .then((response: any) => {
        this.$store.dispatch('closeDialog', this.mfaDialogOptions);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get mfaDialogOptions(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet u zeker dat uw de ingestelde MFA token van deze gebruiker will verwijderen. Volgende keer als de gebruiker probeert in te loggen moet hij/zij hun token opnieuw instellen.',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            this.deleteMfaRequest();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }
}
