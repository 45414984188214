var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          !_vm.isLoading
            ? _c("template", { slot: "header" }, [
                _c("h2", { staticClass: "dialog-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.holiday ? "Vakantie aanpassen" : "Vakantie toevoegen"
                    )
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-space-between": "",
                        "align-baseline": "",
                        "mb-4": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "dialog",
                              attrs: {
                                "close-on-content-click": false,
                                lazy: "",
                                "nudge-right": "100",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "input input-date",
                                attrs: {
                                  slot: "activator",
                                  placeholder: "DD-MM-YYYY",
                                  label: "Datum vanaf",
                                  clearable: "",
                                  "error-messages": _vm.fromDate.errorMessage
                                },
                                on: {
                                  "click:clear": function($event) {
                                    return _vm.fromDate.clear()
                                  }
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.fromDate.formattedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fromDate, "formattedDate", $$v)
                                  },
                                  expression: "fromDate.formattedDate"
                                }
                              }),
                              _c("v-date-picker", {
                                ref: "picker",
                                attrs: {
                                  "first-day-of-week": "1",
                                  locale: "nl-nl",
                                  min: "1910-01-01",
                                  color: "#837f16"
                                },
                                model: {
                                  value: _vm.fromDate.selectedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fromDate, "selectedDate", $$v)
                                  },
                                  expression: "fromDate.selectedDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "dialog",
                              attrs: {
                                "close-on-content-click": false,
                                lazy: "",
                                "nudge-right": "100",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "input input-date",
                                attrs: {
                                  slot: "activator",
                                  placeholder: "DD-MM-YYYY",
                                  label: "Datum tot",
                                  clearable: "",
                                  "error-messages": _vm.toDate.errorMessage
                                },
                                on: {
                                  "click:clear": function($event) {
                                    return _vm.toDate.clear()
                                  }
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.toDate.formattedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.toDate, "formattedDate", $$v)
                                  },
                                  expression: "toDate.formattedDate"
                                }
                              }),
                              _c("v-date-picker", {
                                ref: "picker",
                                attrs: {
                                  "first-day-of-week": "1",
                                  locale: "nl-nl",
                                  min: "1910-01-01",
                                  color: "#837f16"
                                },
                                model: {
                                  value: _vm.toDate.selectedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.toDate, "selectedDate", $$v)
                                  },
                                  expression: "toDate.selectedDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: { label: "Opmerking" },
                            model: {
                              value: _vm.comment,
                              callback: function($$v) {
                                _vm.comment = $$v
                              },
                              expression: "comment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fadedText link mr-3",
                              on: { click: _vm.close }
                            },
                            [_vm._v("Annuleren")]
                          ),
                          _c(
                            "MiButton",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                disabled: _vm.isDisabled,
                                color: "success",
                                small: "true"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.create($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.holiday ? "Aanpassen" : "Toevoegen"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }