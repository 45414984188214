import { Component, Vue, Prop } from 'vue-property-decorator';
import EnrollmentListDialog from '@/views/Users/ExpertRegister/EnrollmentListDialog/EnrollmentListDialog.vue';
import { Experience, ExperiencePayload, ExperienceType } from '@/models/Experience';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { User } from '@/models/User';
import { cloneDeep } from 'lodash';

@Component<EnrollmentList>({
  components: {
    EnrollmentListDialog,
  },
})
export default class EnrollmentList extends Vue {
  @Prop()
  protected experiences!: {[key: string]: Experience[]};

  @Prop()
  protected organization!: string;

  @Prop()
  protected title!: string;

  @Prop()
  protected user!: User;

  @Prop()
  protected singular!: string;

  @Prop()
  protected noResultsText!: string;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop({ })
  protected type!: ExperienceType;

  protected showExperienceDialog = false;

  protected editMode = false;

  protected selectedExperience: Experience | null = null;

  protected async mounted() {
    //
  }

  protected filterExperiences(key: string) {
    if (! this.user?.experiences) { return; }
    return this.user.experiences.filter((experience) => experience.type === key);
  }

  protected async updateExperience(experience: Experience) {
    const model = this.organization ? new Experience(experience).dmz(this.organization) : new Experience(experience);
    const newExperience: Experience = await model
      .put()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (newExperience?.type) {
      this.reloadExperiences();
    }
  }

  protected async createExperience(payload: ExperiencePayload) {
    const model = this.organization ? new Experience().dmz(this.organization) : new Experience();
    const experience: Experience = await model
      .create(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (experience?.type) {
      this.reloadExperiences();
    }
  }

  protected async reloadExperiences() {
    this.$emit('reload');
    this.showExperienceDialog = false;
    this.selectedExperience = null;
  }

  protected async deleteExperience(experience: Experience) {
    const model = this.organization ? new Experience(experience).dmz(this.organization) : new Experience(experience);

    const response = await model
      .delete();

    if (response) {
      this.$emit('reload');
    }
  }

  protected editExperience(experience: Experience): void {
    this.selectedExperience = experience;
    this.editMode = true;
    this.showExperienceDialog = true;
  }

  protected addExperience() {
    this.editMode = false;
    this.showExperienceDialog = true;
  }

  protected get experiencesData(): Experience[] {
    return this.filterExperiences(this.type) || [];
  }

  protected get isSchool() {
    return this.type === ExperienceType.SCHOOL;
  }
}
