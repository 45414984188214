import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { User } from '@/models/User';
import _ from 'lodash';

@Component<QRDialog>({})
export default class QRDialog extends Vue {
  @Prop({ default: null })
  protected user!: User;

  protected qrImage = '';

  protected token1 = '';

  protected token2 = '';

  protected secret = '';

  protected mounted() {
    this.initialize();
  }

  protected initialize() {
    this.fetchQRCode();
  }

  protected fetchQRCode() {
    new User()
      .getQR(this.user.uuid)
      .then((response: any) => {
        this.qrImage = response.qr_image;
        this.secret = response.secret;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected create() {
    const payload = {
      secret: this.secret,
      token1: this.token1,
      token2: this.token2,
    };

    this.user
      .storeQr(payload, this.user.uuid)
      .then(() => {
        this.$emit('input', false);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.$emit('input', false);
  }
}
