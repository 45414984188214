import { PostalCode } from '@/models/PostalCode';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';

import { debounce } from 'lodash';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<PostalCodeExclusion>({})
export default class PostalCodeExclusion extends Vue {
  @Prop()
  protected user!: User;

  @Prop()
  protected organization!: string;

  @Prop()
  protected userId!: string;

  @Prop({ default: true })
  protected canEdit!: boolean;

  // models
  protected postalCodes: PostalCode[] = [];

  protected excludedPostalCodes: PostalCode[] = [];

  protected selectedExcludedPostalCode = '';

  // render bools
  protected isLoading = true;

  // search
  protected postalCodeSearch = '';

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected mounted() {
    if (! this.user) {
      return;
    }

    this.initialize();
  }

  /**
   * called by mounted() and by 'sluiten' button, serves as reset
   * calls postcode backend for all postcodes
   *
   * @memberof PostalCodeExclusion
   */
  protected initialize() {
    Promise.all([this.getGeneralPostalCodes(), this.getUserExcludedPostalCodes()])
      .then((results: [PostalCode[] | AxiosError, PostalCode[] | AxiosError]) => {
        this.postalCodes = (results[0] as PostalCode[]);
        this.excludedPostalCodes = results[1] as PostalCode[];
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected getGeneralPostalCodes(): Promise<PostalCode[] | AxiosError> {
    return new PostalCode()
      .all()
      .then((postalCodes: PostalCode[]) => Promise.resolve(postalCodes))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected getUserExcludedPostalCodes(): Promise<PostalCode[] | AxiosError> {
    const user = this.organization ? new User().dmz(this.organization) : new User();

    return user
      .limit(300)
      .getPostalCodes(this.userId ? this.userId : this.$route.params.id)
      .then((postalCodes: PostalCode[]) => Promise.resolve(postalCodes))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected handleSearch() {
    new PostalCode()
      .filter({ search: this.postalCodeSearch })
      .all()
      .then((postalCodes: PostalCode[]) => {
        this.postalCodes = postalCodes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected addPostalCodes() {
    if (! this.selectedExcludedPostalCode) {
      return;
    }

    const payload = this.excludedPostalCodes.map((postalCode: PostalCode) => ({
      id: postalCode.id,
      is_excluded: true,
    }));

    payload.push({
      id: this.selectedExcludedPostalCode,
      is_excluded: true,
    });

    this.putPostalCodes(payload);
  }

  protected removePostalCodes(postalCode: PostalCode) {
    if (! postalCode) {
      return;
    }

    if (! this.canEdit) {
      return;
    }

    const excludedPostalCodes = this.excludedPostalCodes.filter((excludePostalCode: PostalCode) => excludePostalCode.id !== postalCode.id);

    const payload = excludedPostalCodes.map((postalCode: PostalCode) => ({
      id: postalCode.id,
      is_excluded: true,
    }));

    this.putPostalCodes(payload);
  }

  protected get canExclude() {
    return this.$store.state.isServiceOrganization && this.$store.state.Auth.is_planner;
  }

  protected putPostalCodes(payload: any) {
    const user = this.organization ? new User().dmz(this.organization) : new User();

    user
      .addPostalCodes(this.userId ? this.userId : this.$route.params.id, payload)
      .then((postalCodes: PostalCode[]) => {
        this.excludedPostalCodes = postalCodes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
