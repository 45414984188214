var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "elementPanel elementPanel--spaced elementPanel--user" },
        [
          _vm.$store.state.Auth.uuid === _vm.userId ||
          _vm.$store.state.Auth.is_planner
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("span", { staticClass: "profile__userName" }, [
                      _vm._v("Notitie")
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { x12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          name: "notitie",
                          label: "Notitieveld",
                          disabled: !_vm.$store.state.Auth.is_planner
                        },
                        on: { change: _vm.updateUserNote },
                        model: {
                          value: _vm.note,
                          callback: function($$v) {
                            _vm.note = $$v
                          },
                          expression: "note"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("span", { staticClass: "profile__userName" }, [
                  _vm._v("Werktijden")
                ])
              ]),
              _c(
                "v-flex",
                { staticClass: "text-xs-right", attrs: { xs6: "" } },
                [
                  _vm.hasBeenChanged
                    ? _c(
                        "span",
                        { staticClass: "save-warning" },
                        [
                          _c("v-icon", [_vm._v("warning")]),
                          _vm._v(" Je hebt niet opgeslagen wijzigingen. ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canEdit
                    ? _c(
                        "MiButton",
                        {
                          attrs: {
                            disabled: _vm.isLoading || !_vm.canSubmitHours,
                            color: "success",
                            small: "true",
                            icon: _vm.isLoading ? "" : "save"
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateHours()
                            }
                          }
                        },
                        [
                          _vm.isLoading
                            ? _c("LoaderCard", {
                                staticClass: "spinner--inline",
                                attrs: { type: "spinner--small" }
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.isLoading
                                  ? "Werktijden worden opgeslagen"
                                  : "Opslaan"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.days.length
            ? _vm._l(_vm.days, function(day, index) {
                return _c(
                  "v-layout",
                  {
                    key: "day-" + index,
                    staticClass: "time__row",
                    class: !_vm.isValidDay(day) ? "not-valid" : "",
                    attrs: { row: "", wrap: "", "align-center": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "day__label", attrs: { xs2: "" } },
                      [_vm._v(" " + _vm._s(day.label) + " ")]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs3: "" } },
                      [
                        _c("v-text-field", {
                          staticClass: "time__input",
                          attrs: {
                            label: "Starttijd ochtend",
                            type: "time",
                            "hide-details": "",
                            disabled: !_vm.canEdit
                          },
                          model: {
                            value: day.starts_at,
                            callback: function($$v) {
                              _vm.$set(day, "starts_at", $$v)
                            },
                            expression: "day.starts_at"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs3: "" } },
                      [
                        _c("v-text-field", {
                          staticClass: "time__input",
                          attrs: {
                            label: "Eindtijd middag",
                            type: "time",
                            "hide-details": "",
                            disabled: !_vm.canEdit
                          },
                          model: {
                            value: day.ends_at,
                            callback: function($$v) {
                              _vm.$set(day, "ends_at", $$v)
                            },
                            expression: "day.ends_at"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            : _vm._e(),
          _vm.isLoading
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pa-0": "" } },
                [
                  _c("LoaderCard", {
                    staticClass: "main-loader",
                    attrs: {
                      extraClass: "availability",
                      type: "dots",
                      flat: true
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? [
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      {
                        staticClass: "elementPanel my-4 mx-0",
                        attrs: { row: "", wrap: "", "pa-0": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass:
                              "availabilityPanel availabilityPanel--no-shadow",
                            attrs: { xs12: "", "pa-0": "" }
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "", "ma-0": "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "availability-toolbar--container",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "availability-toolbar",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": "",
                                          "justify-space-between": "",
                                          "pb-2": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "flex-starving",
                                            attrs: { "ma-2": "" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "profile__userName"
                                              },
                                              [_vm._v("Beschikbaarheid")]
                                            )
                                          ]
                                        ),
                                        _vm.canEdit
                                          ? _c(
                                              "v-flex",
                                              {
                                                staticClass: "flex-starving",
                                                attrs: { "ma-2": "" }
                                              },
                                              [
                                                _c("v-layout", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "toolbar__toggle toolbar__toggle--free",
                                                      class: {
                                                        selected:
                                                          _vm.selectedToggle &&
                                                          _vm.selectedToggle ===
                                                            "free"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectToggle(
                                                            "free"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("check_circle")
                                                      ]),
                                                      _vm._v(" Beschikbaar ")
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "toolbar__toggle toolbar__toggle--busy",
                                                      class: {
                                                        selected:
                                                          _vm.selectedToggle &&
                                                          _vm.selectedToggle ===
                                                            "busy"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectToggle(
                                                            "busy"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("remove_circle")
                                                      ]),
                                                      _vm._v(
                                                        " Niet Beschikbaar "
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "toolbar__toggle toolbar__toggle--internal",
                                                      class: {
                                                        selected:
                                                          _vm.selectedToggle &&
                                                          _vm.selectedToggle ===
                                                            "internal"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectToggle(
                                                            "internal"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("apartment")
                                                      ]),
                                                      _vm._v(
                                                        " Intern beschikbaar "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "availability-toolbar--container",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "availability-toolbar",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": "",
                                          "justify-space-between": "",
                                          "pb-2": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "flex-starving",
                                            attrs: { "ma-2": "" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "profile__userName"
                                              },
                                              [_vm._v("Even")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "availability-list--container",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "item__list item__list--table availability-list availability-list--expert",
                                        class: {
                                          toolbarSelected: _vm.selectedToggle
                                        },
                                        attrs: { wrap: "" }
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            {
                                              staticClass: "label header__row",
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "daypart__column"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Dagdeel")
                                                  ])
                                                ]
                                              ),
                                              _vm._l(_vm.even, function(day) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: day.day + "-cell",
                                                    staticClass: "day__cell"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--day"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.short_label
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]),
                                        _c(
                                          "tbody",
                                          { staticClass: "expert-row" },
                                          [
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--morning"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--morning"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Ochtend")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(_vm.even, function(day) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: day.day + "-cell",
                                                      staticClass:
                                                        "list__input",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleCellClick(
                                                            day,
                                                            "morning"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "availability--container",
                                                          attrs: {
                                                            "data-daypart":
                                                              "morning",
                                                            "data-day": day.day
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability__indicator",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "morning",
                                                                "data-day":
                                                                  day.day,
                                                                "data-type":
                                                                  day.daypart_morning
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "item__icon v-icon material-icons theme--light",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEventIcon(
                                                                          day,
                                                                          "morning"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dots dots--small availability"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce1"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce2"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce3"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--morning"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--morning"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Middag")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(_vm.even, function(day) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: day.day + "-cell",
                                                      staticClass:
                                                        "list__input",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleCellClick(
                                                            day,
                                                            "afternoon"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "availability--container",
                                                          attrs: {
                                                            "data-daypart":
                                                              "afternoon",
                                                            "data-day": day.day
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability__indicator",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.day,
                                                                "data-type":
                                                                  day.daypart_afternoon
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "item__icon v-icon material-icons theme--light",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEventIcon(
                                                                          day,
                                                                          "afternoon"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dots dots--small availability"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce1"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce2"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce3"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--afternoon"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--afternoon"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Afspraken")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm.hasAppointmentSubdivision
                                                  ? _vm._l(_vm.even, function(
                                                      day
                                                    ) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key:
                                                            day.day + "-cell",
                                                          staticClass:
                                                            "list__input no-white-space"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container px-2 appointment-container",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.label
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showAppointmentTypeDialog(
                                                                    day
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      day.max_appointment_count !==
                                                                        null
                                                                        ? day.max_appointment_count +
                                                                            "/" +
                                                                            _vm.maxLimit
                                                                        : _vm.maxLimit
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "20"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "create"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  : _vm._e(),
                                                !_vm.hasAppointmentSubdivision
                                                  ? _vm._l(_vm.even, function(
                                                      day
                                                    ) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key:
                                                            day.day + "-cell",
                                                          staticClass:
                                                            "list__input no-white-space"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container px-2",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.day
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Aantal",
                                                                    rules: [
                                                                      _vm.limitRule
                                                                    ],
                                                                    min: 0,
                                                                    type:
                                                                      "number",
                                                                    clearable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      day.max_appointment_count,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        day,
                                                                        "max_appointment_count",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "day.max_appointment_count"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "availability-toolbar--container",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "availability-toolbar",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": "",
                                          "justify-space-between": "",
                                          "pb-2": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "flex-starving",
                                            attrs: { "ma-2": "" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "profile__userName"
                                              },
                                              [_vm._v("Oneven")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "availability-list--container",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "item__list item__list--table availability-list availability-list--expert",
                                        class: {
                                          toolbarSelected: _vm.selectedToggle
                                        },
                                        attrs: { wrap: "" }
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            {
                                              staticClass: "label header__row",
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "daypart__column"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Dagdeel")
                                                  ])
                                                ]
                                              ),
                                              _vm._l(_vm.uneven, function(day) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: day.day + "-cell",
                                                    staticClass: "day__cell"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--day"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.short_label
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]),
                                        _c(
                                          "tbody",
                                          { staticClass: "expert-row" },
                                          [
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--morning"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--morning"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Ochtend")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(_vm.uneven, function(
                                                  day
                                                ) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: day.day + "-cell",
                                                      staticClass:
                                                        "list__input",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleCellClick(
                                                            day,
                                                            "morning"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "availability--container",
                                                          attrs: {
                                                            "data-daypart":
                                                              "morning",
                                                            "data-day": day.day
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability__indicator",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "morning",
                                                                "data-day":
                                                                  day.day,
                                                                "data-type":
                                                                  day.daypart_morning
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "item__icon v-icon material-icons theme--light",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEventIcon(
                                                                          day,
                                                                          "morning"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dots dots--small availability"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce1"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce2"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce3"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--morning"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--morning"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Middag")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(_vm.uneven, function(
                                                  day
                                                ) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: day.day + "-cell",
                                                      staticClass:
                                                        "list__input",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleCellClick(
                                                            day,
                                                            "afternoon"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "availability--container",
                                                          attrs: {
                                                            "data-daypart":
                                                              "afternoon",
                                                            "data-day": day.day
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability__indicator",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.day,
                                                                "data-type":
                                                                  day.daypart_afternoon
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "item__icon v-icon material-icons theme--light",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEventIcon(
                                                                          day,
                                                                          "afternoon"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "dots dots--small availability"
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce1"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce2"
                                                              }),
                                                              _c("div", {
                                                                staticClass:
                                                                  "bounce3"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _c(
                                              "tr",
                                              { staticClass: "list__item" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "daypart__cell daypart__cell--afternoon"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "daypart__container daypart__container--afternoon"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Afspraken")
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm.hasAppointmentSubdivision
                                                  ? _vm._l(_vm.uneven, function(
                                                      day
                                                    ) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key:
                                                            day.day + "-cell",
                                                          staticClass:
                                                            "list__input no-white-space"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container px-2 appointment-container",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.label
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showAppointmentTypeDialog(
                                                                    day
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      day.max_appointment_count !==
                                                                        null
                                                                        ? day.max_appointment_count +
                                                                            "/" +
                                                                            _vm.maxLimit
                                                                        : _vm.maxLimit
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "20"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "create"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  : _vm._e(),
                                                !_vm.hasAppointmentSubdivision
                                                  ? _vm._l(_vm.uneven, function(
                                                      day
                                                    ) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key:
                                                            day.day + "-cell",
                                                          staticClass:
                                                            "list__input no-white-space"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container px-2",
                                                              attrs: {
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day":
                                                                  day.day
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Aantal",
                                                                    rules: [
                                                                      _vm.limitRule
                                                                    ],
                                                                    min: 0,
                                                                    type:
                                                                      "number",
                                                                    clearable:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      day.max_appointment_count,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        day,
                                                                        "max_appointment_count",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "day.max_appointment_count"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    })
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "", "align-center": "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Vakanties")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "MiButton",
                {
                  attrs: { icon: "add", compact: "true", color: "secondary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onClickCreateHoliday()
                    }
                  }
                },
                [_vm._v(" Toevoegen ")]
              )
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { x12: "" } },
                      [
                        _c("DataTable", {
                          ref: "holidayDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            visibility: _vm.visibility
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isCreatingHolidayDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.isCreatingHolidayDialog,
                callback: function($$v) {
                  _vm.isCreatingHolidayDialog = $$v
                },
                expression: "isCreatingHolidayDialog"
              }
            },
            [
              _c("CreateHolidayDialog", {
                attrs: { user: _vm.user, holiday: _vm.selectedHoliday },
                on: {
                  holidayCreated: function($event) {
                    return _vm.refreshTable()
                  }
                },
                model: {
                  value: _vm.isCreatingHolidayDialog,
                  callback: function($$v) {
                    _vm.isCreatingHolidayDialog = $$v
                  },
                  expression: "isCreatingHolidayDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplayingAppointmentTypeDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              model: {
                value: _vm.isDisplayingAppointmentTypeDialog,
                callback: function($$v) {
                  _vm.isDisplayingAppointmentTypeDialog = $$v
                },
                expression: "isDisplayingAppointmentTypeDialog"
              }
            },
            [
              _c("AppointmentTypeDialog", {
                attrs: {
                  user: _vm.user,
                  day: _vm.appointmentType.day,
                  max: _vm.maxLimit,
                  settings: _vm.settings
                },
                on: {
                  updateMaxAppointmentTypeCount:
                    _vm.updateMaxAppointmentTypeCount
                },
                model: {
                  value: _vm.isDisplayingAppointmentTypeDialog,
                  callback: function($$v) {
                    _vm.isDisplayingAppointmentTypeDialog = $$v
                  },
                  expression: "isDisplayingAppointmentTypeDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }